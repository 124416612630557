<template>
  <div>
    <div class="flex justify-between">
      <div class="flex">
        <div class="w-[350px]">
          <Datepicker v-model="dateRange" range @update:model-value="handleDateChange" />
        </div>
        <div>

          <select
            class="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 mx-2 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-state" @change="handleChangeLimit" v-model="record_limit">
            <option :key="limit" :value="limit" v-for="limit in [10,20,30,50,75,100]">Limit {{ limit }}</option>
          
          </select>
        </div>
      </div>


      <button @click="handleClickViewTable"
        class="bg-gray-300 hover:bg-gray-600 focus:outline-none rounded-lg px-6 py-2 text-gray-500 font-semibold shadow">
        View Table
      </button>
    </div>
    <div ref="chart"></div>
    <ModalBox v-model="modelViewTablePerformance" :title="'View Table Detail'">
      <DynamicTable :headers="headersTable" :rows="dilldownRecord" :action="false">
        <template #_id="{ index }">
          {{ index + 1 }}
        </template>
        <template #username="{ record }">
          <a target="_blank" :href="`https://twitter.com/${record.username}`">
            <span
              class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              {{ record.username }}
            </span>
          </a>
        </template>
      </DynamicTable>
    </ModalBox>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchWrapper } from "../../helper/fetch-wrapper";
import Highcharts from "highcharts";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useRouter } from "vue-router";
import ModalBox from "../../components/ModalBox.vue";
import DynamicTable from "../../components/DynamicTable.vue";


export default {
  name: "BarChart",
  components: {
    Datepicker,
    ModalBox,
    DynamicTable,

  },
  data() {
    return {
      loaded: false,
    };
  },
  setup() {
    const router = useRouter();

    const chart = ref(null);
    const record_limit = ref(20)
    const modelViewTablePerformance = ref(false);
    var dilldownRecord = ref([]);
    onMounted(() => {
      // renderChart()
      fetchData();
    });

    const handleClickChart = (record) => {
      const { kol_member_id } = record.point.series.userOptions;
      router.push(`/home/kol-member/profile/${kol_member_id}`);
    };

    const handleChangeLimit = () => {
      console.log(record_limit.value);
      fetchData()
    }

    const chart_option = ref({
      chart: {
        type: "column",
      },
      plotOptions: {
        column: {
          events: {
            click: handleClickChart,
          },
        },
      },
      title: {
        text: "KOL Perfomance",
      },
      xAxis: {
        categories: ["Perfomance User View", "KOl tweet amount"],
      },
      yAxis: {
        title: {
          text: "View count",
        },
      },
      tooltip: {
        formatter: function () {
          return `${this.series.name} : <b>${Number(
            this.y
          ).toLocaleString()}</b> <br/>Total Tweet amount : <b>${Number(
            this.series.userOptions.tweet_count || 0
          ).toLocaleString()}</b>`;
        },
      },
      series: [
        {
          name: "Player 1",
          // backgroundColor: '#f87979',
          data: [40, 2],
        },
        {
          name: "Player 2",
          // backgroundColor: '#f87979',
          data: [20, 5],
        },
      ],
    });

    const renderChart = (chart_option) => {
      if (chart.value) {
        Highcharts.chart(chart.value, chart_option);
      }
    };

    const startOfYear = moment().startOf("year");
    const endOfYear = moment().endOf("year");

    const dateRange = ref([startOfYear, endOfYear]);

    const handleDateChange = () => {
      fetchData();
    };

    const handleViewTable = async () => {
      dilldownRecord.value = [];

      var data_body = {
        date_start: moment(dateRange.value[0]).format("YYYY-MM-DD HH:mm:ss"),
        date_end: moment(dateRange.value[1]).format("YYYY-MM-DD HH:mm:ss"),
        limit: 1000,
      };

      const view_data = await fetchWrapper.post(
        "/api/dashboard/kol-performance",
        data_body
      );
      if (view_data) {
        dilldownRecord.value = view_data.data;
        modelViewTablePerformance.value = true;
      }
    };

    const columns = [
      {
        title: "#",
        dataIndex: "_id",
      },
      {
        title: "Username",
        dataIndex: "username",
      },
      {
        title: "Tweet Count",
        dataIndex: "tweet_count",
      },
      {
        title: "Total View All Tweet Count",
        dataIndex: "total_impression_count",
      },
      {
        title: "Total Retweet Count",
        dataIndex: "total_retweet_count",
      },
      {
        title: "Total Reply Count",
        dataIndex: "total_reply_count",
      },
      {
        title: "Total Like Count",
        dataIndex: "total_like_count",
      },
      {
        title: "Total Quote Count",
        dataIndex: "total_quote_count",
      },
      {
        title: "Total Bookmark Count",
        dataIndex: "total_bookmark_count",
      },
    ];

    var headersTable = ref(columns);

    const fetchData = async () => {
      if (dateRange.value != null) {
        fetchWrapper
          .post("/api/dashboard/kol-performance", {
            date_start: moment(dateRange.value[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            date_end: moment(dateRange.value[1]).format("YYYY-MM-DD HH:mm:ss"),
            limit: Number(record_limit.value),
          })
          .then((response) => {
            chart_option.value.series = response.data.map((record) => {
              return {
                name: record.username,
                data: [record.total_impression_count],
                tweet_count: record.tweet_count,
                kol_member_id: record.kol_member_id,
              };
            });

            renderChart(chart_option.value);
          });
      }
    };

    const handleClickViewTable = async () => {
      modelViewTablePerformance.value = true;
      handleViewTable();
    };

    return {
      chart,
      dateRange,
      handleDateChange,
      modelViewTablePerformance,
      headersTable,
      dilldownRecord,
      handleClickViewTable,
      handleChangeLimit,
      record_limit
    };
  },
};
</script>
