<template>
  <div id="home">
    <!-- breadcrumb -->
    <!-- <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center text-blue-500">
          <a href="#" class="text-gray-700">Home</a>
          <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
        </li>
        <li class="flex items-center text-blue-500">
          <a href="#" class="text-gray-600">KOLMember</a>
          <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
        </li>
        <li class="flex items-center text-blue-500">
          <a href="#" class="text-gray-600">Create</a>
        </li>
      </ol>
    </nav> -->
    <!-- breadcrumb end -->
    <div class="container mx-auto">
      <form @submit.prevent="submitForm" class="max-w-md mx-auto mt-8 bg-white p-8 rounded-md shadow-md">
        <div class="mb-4">
          <label for="twitter_user_id" class="block text-gray-700 font-bold mb-2">Twitter User ID:</label>
          <input v-model="form.twitter_user_id" type="text" id="twitter_user_id" name="twitter_user_id"
            class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <label for="followers_count" class="block text-gray-700 font-bold mb-2">Followers Count:</label>
          <input v-model.number="form.followers_count" type="number" id="followers_count" name="followers_count"
            class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <label for="username" class="block text-gray-700 font-bold mb-2">Username:</label>
          <input v-model="form.username" type="text" id="username" name="username"
            class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <label for="cost_usdt" class="block text-gray-700 font-bold mb-2">Cost (USDT):</label>
          <input v-model.number="form.cost_usdt" type="number" id="cost_usdt" name="cost_usdt"
            class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
        </div>
        <div class="mb-4">
          <label for="is_active" class="block text-gray-700 font-bold mb-2">Is Active:</label>
          <input v-model="form.is_active" type="checkbox" id="is_active" name="is_active" class="mr-2 leading-tight">
          <span class="text-sm">Active</span>
        </div>
        <div class="flex justify-end">
          <button type="submit"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Update</button>
        </div>
      </form>
    </div>

    <!-- <div class="flex flex-wrap p-3  border  rounded-md">


    </div> -->

  </div>
</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper";
export default {
  props: {
    member_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        twitter_user_id: '',
        followers_count: 0,
        username: '',
        cost_usdt: 0,
        is_active: true
      },
      id: null,
    };
  },
  methods: {
    async submitForm() {
      // Handle form submission
  
      // You can perform API calls here to create the data
      await fetchWrapper.put("/api/member/update", this.form).then((data) => {
        // this.$store.dispatch('setLoading', false)
        this.$emit("updated");
        this.$toast.open({ type: "success", message: "Update KOL member successfully" })
        return data
      }).catch((error) => {
        // this.$store.dispatch('setLoading', false)
       
        alert(`${error}`)
        this.$toast.open({ type: "error", message: error.message })
        this.$emit("updated");
      })
      this.back()
    },
    back() {
      // Handle form submission
     
      this.$router.push({ name: 'KolMember' });
      // You can perform API calls here to create the data
    },
    async getById(id) {

      if (id == undefined) {
        id = this.$route.params.id
      }
      const member = fetchWrapper.get("/api/member/" + id).then((data) => {
        // console.log(data)
        return data.data
      }).catch((error) => {
        alert(`Error: ${error}`);
        // this.$emit("updated");
        this.back()
      })
      return member
    }
  },
  async created() {
    const member = await this.getById(this.member_id)
    this.form = member
  }
};
</script>