<template>
  <table class="w-full table-auto text-sm text-left">
    <thead class="bg-white text-gray-600 font-medium border-b ">
      <tr class="hover:bg-gray-200">
        <th class="py-2 px-2 " v-for="(header, index) in headers" :key="index">
          <div class="flex w-full justify-between items-center">
            <div> {{ header.title }}</div>
            <div v-if="header?.sorter != undefined">
              <div class="cursor-pointer" :style="{ color: (header?.sorter.order == 1) ? 'blue' : null }"
                @click="handleSort(1, index)" v-html="upIcon"></div>
              <div class="cursor-pointer" :style="{ color: (header?.sorter.order == -1) ? 'blue' : null }"
                @click="handleSort(-1, index)" v-html="downIcon"></div>
            </div>
          </div>

        </th>
        <th class="py-3 px-6" v-if="slots?.action_custom">Action</th>
      </tr>

    </thead>
    <tbody class="text-gray-600 divide-y">

      <tr v-if="!rows.length" class="text-center hover:bg-gray-200 ">
        <td :colspan="headers.length" class="py-4">
          no data!
        </td>
      </tr>
      <tr v-for="(row, rowIndex) in rows" :key="rowIndex" class="bg-white ">
        <template v-for="(header,header_index) in headers">
          <td :key="header.dataIndex" class="px-6 py-4 whitespace-nowrap" v-if="row[header.dataIndex] != undefined">
            <slot :name="header.dataIndex" :column="header.dataIndex" :record="row" :index="rowIndex"
              v-if="slots[header.dataIndex]">
            </slot>
            <template v-else>
              {{ row[header.dataIndex] }}
            </template>
          </td>
          <td :key="header_index"  class="px-6 py-4 whitespace-nowrap" v-else>
            <slot :name="header.dataIndex" :column="header.dataIndex" :record="row" :index="rowIndex"
              v-if="slots[header.dataIndex]">
            </slot>
            <template v-else>

            </template>
          </td>
          <!-- <template v-for="(cell, cellIndex) in row" :key="cellIndex">
            <template v-if="header.dataIndex == cellIndex">
              <td class="px-6 py-4 whitespace-nowrap">
                <slot :name="header.dataIndex" :column="header.dataIndex" :record="row" :index="rowIndex"
                  v-if="slots[header.dataIndex]">
                </slot>
                <template v-else>
                  {{ cell }}
                </template>
              </td>
            </template>
           
          </template> -->
        </template>
        <td v-if="slots?.action_custom">
          <slot :name="'action_custom'" :index="rowIndex" :record="row" :column="'action_custom'"></slot>
        </td>
      </tr>

    </tbody>
  </table>

</template>

<script setup>
import { useSlots, ref } from "vue"

const slots = useSlots()

const upIcon = ref(`
<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
</svg>
`)

const downIcon = ref(`
<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
`)
</script>


<script>


export default {
  name: 'DynamicTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    action: {
      type: Boolean,
      default: false
    },
    pathApiDelete: {
      type: String,
      required: true,
    }
  },
  methods: {
    handleSort(type, index) {
      var new_header = this.headers.map((header) => {
        if (header.sorter != undefined) {
          header.sorter.order = 0
        }
        return header
      })

      new_header[index].sorter.order = type


      // handle-sort
      this.$emit("handle-sort", new_header, { [new_header[index].dataIndex]: type });
    }
  }
};
</script>