<template>
    <div>
        <!-- Pagination for desktop -->
        <div class="max-w-screen-xl mx-auto mt-12 px-4 text-gray-600 md:px-8">
            <div class="hidden items-center justify-between sm:flex" aria-label="Pagination">
                <button @click="handlePrevPage" :disabled="!hasPrevPage"
                    class="hover:text-indigo-600 flex items-center gap-x-2"
                    :style="{ color: (hasPrevPage == false) ? '#d1d1d1' : null }">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd"
                            d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                            clip-rule="evenodd" />
                    </svg>
                    Previous
                </button>
                <ul class="flex items-center gap-1">

                    <li v-for="page  in visiblePages()" :key="page" class="text-sm">
                        <a href="#" class="px-3 py-2 rounded-lg duration-150" :class="{
                    'hover:text-indigo-600 hover:bg-indigo-50 bg-indigo-50 text-indigo-600 font-medium':
                        (page) === pages,
                    'hover:text-indigo-600 hover:bg-indigo-50':
                        (page) !== pages,
                }" @click.prevent="goToPage(page)">
                            {{ page }}
                        </a>
                    </li>
                </ul>
                <button @click="handleNextPage" :disabled="!hasNextPage"
                    class="hover:text-indigo-600 flex items-center gap-x-2"
                    :style="{ color: (hasNextPage == false) ? '#d1d1d1' : null }">
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd"
                            d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
        <!-- Pagination for mobile -->
        <div class="flex items-center justify-between text-sm text-gray-600 font-medium sm:hidden">
            <a href="#" class="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
                @click="handlePrevPage">Previous</a>
            <div class="font-medium">
                Page {{ pages }} of {{ totalPages }}
            </div>
            <a href="#" class="px-4 py-2 border rounded-lg duration-150 hover:bg-gray-50"
                @click="handleNextPage">Next</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hasPrevPage: {
            type: Boolean,
            default: false
        },
        prevPage: {
            type: Number,
            default: 1
        },
        nextPage: {
            type: Number,
            default: 1
        },
        hasNextPage: {
            type: Boolean,
            default: false
        },
        pages: {
            type: Number,
            default: 1
        },
        totalPages: {
            type: Number,
            default: 1
        }
    },
    methods: {
        visiblePages() {
            const totalPages = this.totalPages;
            const currentPage = this.pages;
            const pageArray = [];

            if (totalPages <= 10) {
                return Array.from({ length: totalPages }, (_, i) => i + 1);
            } else {
                if (currentPage <= 4) {
                    for (let i = 1; i <= 5; i++) {
                        pageArray.push(i);
                    }
                    pageArray.push('...');
                    for (let i = totalPages - 1; i <= totalPages; i++) {
                        pageArray.push(i);
                    }
                } else if (currentPage >= totalPages - 3) {
                    for (let i = 1; i <= 2; i++) {
                        pageArray.push(i);
                    }
                    pageArray.push('...');
                    for (let i = totalPages - 4; i <= totalPages; i++) {
                        pageArray.push(i);
                    }
                } else {
                    pageArray.push(1, '...');
                    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                        pageArray.push(i);
                    }
                    pageArray.push('...', totalPages);
                }

                return pageArray;
            }
        },
        handlePrevPage() {
            if (this.hasPrevPage) {
                // this.pages--;
                this.$emit("page-changed", {
                    limit: 10,
                    skip: this.prevPage,
                    sort: { _id: -1 },
                    search: "",
                    active: true,
                });
            }
        },
        handleNextPage() {
            
            if (this.hasNextPage) {
                // this.pages++;
                this.$emit("page-changed", {
                    limit: 10,
                    skip: this.nextPage,
                    sort: { _id: -1 },
                    search: "",
                    active: true,
                });
            }
        },
        goToPage(page) {
            if (page != "...") {
                this.$emit("page-changed", {
                    limit: 10,
                    skip: page,
                    sort: { _id: -1 },
                    search: "",
                    active: true,
                });
            }

        },
    }
}
</script>