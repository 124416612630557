<template>
  <div id="home">
    <!-- breadcrumb -->
    <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center text-blue-500">
          <a href="#" class="text-gray-700">Home</a>
          <svg class="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
        </li>
        <li class="flex items-center">
          <a href="#" class="text-gray-600">KOLMember</a>
        </li>
      </ol>
    </nav>
    <!-- breadcrumb end -->

    <div class="lg:flex justify-between items-center mb-6 ">
      <p class="text-2xl font-semibold mb-2 lg:mb-0">Dashboard KOL Tracker</p>

    </div>

    <div class="lg:flex justify-between items-center mb-6 ">
      <div class="relative text-gray-600">
        <input v-model="text_search" @keyup=handleSearch type="search" name="serch" placeholder="Search by username"
          class="bg-white h-10 w-full xl:w-64 px-5 rounded-lg border text-sm focus:outline-none">
        <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
          <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
            viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
            width="512px" height="512px">
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </div>

      <button @click="modelCreateIsOpen = true"
        class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Add
        Member</button>


    </div>

    <div class="flex flex-wrap -mx-3 mb-20 overflow-x-auto">
      <DynamicTable :headers="headersTable" :rows="resData.data" :action="true" :pathApiDelete="''" @deleted="deleted"
        @handle-sort="handleSortHeader">
        <template #_id="{ index }">
          {{ index + 1 }}
        </template>
        <template #username="{ record }">
          <a target="_blank" :href="`https://twitter.com/${record.username}`">
            <span
              class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              {{ record.username }}
            </span>
          </a>
        </template>
        <template #cost_usdt="{ record }">
          {{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(record.cost_usdt) }}
        </template>
        <template #followers_count="{ record }">
          <b>{{ Number(record.followers_count || 0).toLocaleString() }}</b>
        </template>
        <template #action_custom="{ record }">
          <div class="w-[300px]">
            <a class="cursor-pointer m-2 py-2 leading-none px-3 font-medium text-yellow-600 bg-gray-100 hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg"
              @click="openProfile(record._id)">
              View
            </a>
            <a @click="modelUpdateIsOpen = true, member_id = record._id"
              class="cursor-pointer m-2  py-2 px-3 font-medium text-indigo-600 bg-gray-100 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg">
              Edit
            </a>
            <a class="cursor-pointer m-2  py-2 leading-none px-3 font-medium text-red-600 bg-gray-100 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg"
              @click="deleteItem(record._id)">
              Delete
            </a>

          </div>
        </template>
      </DynamicTable>
      <div class="w-full ">
        <div class="flex justify-center items-center">
          <PaginationApp :prevPage="resData.info.prevPage" :nextPage="resData.info.nextPage"
            :hasPrevPage="resData.info.hasPrevPage" :hasNextPage="resData.info.hasNextPage" :pages="resData.info.pages"
            :totalPages="resData.info.totalPages" @page-changed="handlePageChange" />


        </div>

      </div>
      <ModalBox v-model="modelCreateIsOpen" :title="'Create Member'">
        <KOLMemberCreate @created="handleCreate" />
      </ModalBox>
      <ModalBox v-model="modelUpdateIsOpen" :title="'Update Member'">
        <KOLMemberUpdate :member_id="member_id" @updated="handleUpdate" />
      </ModalBox>
    </div>
  </div>
</template>

<script>
import DynamicTable from "../../components/DynamicTable.vue";
import ModalBox from "../../components/ModalBox.vue"
import PaginationApp from "../../components/PaginationApp.vue";
import { fetchWrapper } from "../../helper/fetch-wrapper";
import KOLMemberCreate from "./KOLMemberCreate.vue";
import KOLMemberUpdate from "./KOLMemberUpdate.vue";
import _ from "underscore"
import {
  ref
  //  ,defineAsyncComponent
} from 'vue'

// const PaginationTableApp = defineAsyncComponent(() =>
//   import('../PaginationTableApp.vue')
// )

export default {

  components: {
    DynamicTable,
    PaginationApp,
    ModalBox,
    KOLMemberCreate,
    KOLMemberUpdate
    // PaginationTableApp,
  },
  data() {
    return {
      text_search:"",
      dropdownOptions: [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' }
      ]
    }
  },
  setup() {
    var sort = ref({ _id: -1 })
    var resData = ref({
      status: false,
      data: [],
      info: {
        limit: 0,
        pages: 0,
        totalCount: 0,
        currentCount: 0,
        nextPage: null,
        prevPage: null,
        totalPages: 0,
        hasPrevPage: false,
        hasNextPage: false,
        pagingCounter: 0,
        meta: {},
      },
    })


    const columns = [
      {
        title: '#',
        dataIndex: '_id',
      },
      {
        title: 'Username',
        dataIndex: 'username',
      },
      {
        title: 'Cost USDT',
        dataIndex: 'cost_usdt',
        sorter: {
          order: 0
        }
      },
      // {
      //   title: 'Is Active',
      //   dataIndex: 'is_active',
      // },
      {
        title: 'Date Time',
        dataIndex: 'current_time',
        sorter: {
          order: 0
        }
      },
      {
        title: 'Followers Count',
        dataIndex: 'followers_count',
        sorter: {
          order: 0
        }
      },
      {
        title: 'updatedAt',
        dataIndex: 'updatedAt',
        sorter: {
          order: -1
        }
      },


    ];

    var headersTable = ref(columns)

    var modelCreateIsOpen = ref(false)
    var modelUpdateIsOpen = ref(false)
    var member_id = ref('')



    return {
      resData,
      headersTable,
      sort,
      modelCreateIsOpen,
      modelUpdateIsOpen,
      member_id
    };
  },
  methods: {
    onSearch: _.debounce(function(){
       this.fetchData({
        limit: this.resData.info.limit,
        skip: this.resData.info.pages,
        sort: this.sort,
        search: {username:this.text_search},
        active: true,
      })
    },1000),
    handleSearch() {
   
      this.onSearch()
    },
    openProfile(member_id) {

      this.$router.push(`/home/kol-member/profile/${member_id}`)
    },
    handleSortHeader: function (new_header, sort) {
      this.headersTable = new_header
      this.sort = sort
      this.fetchNew()

    },
    fetchNew: async function () {
      await this.fetchData({
        limit: this.resData.info.limit,
        skip: this.resData.info.pages,
        sort: this.sort,
        search: {},
        active: true,
      })
    },
    handleDelete: async function () {
      await this.fetchNew()
    },
    handlePageChange: async function (info) {
      await this.fetchData({
        limit: info.limit,
        skip: info.skip,
        sort: this.sort,
        search: "",
        active: true,
      })
    },
    fetchData: async function (pagination) {
      try {
        this.$store.dispatch('setLoading', true)
        const member = await fetchWrapper.post("/api/member/all", pagination);
        if (member && member.status) {
          this.resData = member;
          this.$store.dispatch('setLoading', false)
        }
      } catch (error) {
        console.error(error);
      }

    },
    async deleteItem(id) {
      if (confirm(`do you want to delete id: ${id}`)) {
        await fetchWrapper.delete(`/api/member/${id}`).then(async () => {
          await this.handleDelete()
          this.$toast.open({ type: "success", message: "Deleted KOL member successfully" })
        }).catch((error) => {
          this.$toast.open({ type: "error", message: error.message })
          this.back()
        })
      }

    },
    async handleCreate() {
      await this.fetchNew()
      this.modelCreateIsOpen = false
    },
    async handleUpdate() {
      await this.fetchNew()
      this.modelUpdateIsOpen = false
    }
  },
  async created() {
    // Fetch initial data when the component is created
    await this.fetchData({
      limit: 10,
      skip: 1,
      sort: this.sort,
      search: "",
      active: true,
    });
  }

};
</script>