
import Vuex from 'vuex'

var menu = [
    {
        section_name: "Main",
        menu: [
            {
                name: "Dashboard",
                icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="h-6 w-6  mr-2">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
              </svg>`,
                path: "/home/dashboard",
                active: false
            }
        ],

    },
    {
        section_name: "KOL Member",
        menu: [
            {
                name: "View KOL Member",
                icon: ` 
 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="h-6 w-6 fill-current mr-2">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
`,
                path: "/home/kol-member",
                active: false
            }
        ]
    }
]

export default new Vuex.Store({
    state: {
        loading: false,
        sideBarOpen: false,
        menu: menu,
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    },
    getters: {
        loading: state => state.loading,
        sideBarOpen: state => {
            return state.sideBarOpen
        },
        isLogIn: state => !!state.user
    },
    mutations: {
        setLoading(state, loading_status) {
            state.loading = loading_status
        },
        toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
        setUser(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        clearUser(state) {
            state.user = null
            localStorage.removeItem('user')
        }
    },
    actions: {
        setLoading(context, status) {
            context.commit('setLoading', status)
        },
        toggleSidebar(context) {
            context.commit('toggleSidebar')
        },
        login({ commit }, user) {
            commit('setUser', user)
        },
        logout({ commit }) {
            commit('clearUser')
        }
    }
})
