<template>
    <div class="w-full px-3">
        <div class="w-full h-[500px] m-auto overflow-auto bg-white border rounded-lg p-4" @scroll="onScroll">
            <div v-for="tweet in tweets" :key="tweet.tweet_id">
                <Tweet width="550px" align="center" :tweet-id="tweet.tweet_id">
                    <template v-slot:loading>
                        <div class="h-[1024px]">
                            <div class="loading-spinner-overlay">
                                <div class="loading-spinner">
                                    <!-- Insert your loading spinner HTML here -->
                                    <div class="spinner"></div>
                                </div>
                            </div>
                        </div>

                    </template>
                </Tweet>
            </div>
            <div v-if="tweets?.length <= 0" class="flex justify-center items-center h-full">
                <div class="text-center">
                    <h2 class="text-2xl font-bold mb-4">Data Not Found</h2>
                    <p class="text-gray-600">Sorry, the data you are looking for is not available.</p>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
import Tweet from "vue-tweet";
import { fetchWrapper } from '../../helper/fetch-wrapper'
import _ from "underscore"
import { mapState } from 'vuex'

export default {
    props: ["username"],
    components: {
        Tweet,

    },
    data() {
        return {
            tweets: [],
            page: 1,
            pageSize: 1,
            currentPage: 1,
            activePage: 1,
        }
    },
    computed: {
        ...mapState(['loading']),
    },
    methods: {
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight) {
                // this.loadMorePosts()
               
                if (this.loading == false) {
                    this.page += 1
                    this.fetchTweets()
                
                }

            }
        },
        fetchTweets: _.debounce(async function () {
            try {
                this.$store.dispatch('setLoading', true)
                const response = await fetchWrapper.post('/api/tweet/all', { search: { username: this.username }, limit: 5, skip: this.page });

                setTimeout(() => {
                    this.tweets = [...this.tweets, ...response.data.data];

                    this.$store.dispatch('setLoading', false)
                }, 100);

            } catch (error) {
                console.error("Error fetching tweets:", error);
            }
        }, 2000)

    },
    mounted() {
        this.fetchTweets();
    }
}
</script>

<style scoped>
.btn {
    padding: 8px 16px;
    background-color: #6CB4EE;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #45a049;
}

.btn.bg-green-500 {
    background-color: #007FFF;
}

.loading-spinner-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(224, 224, 224, 0.398);
    /* semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>