import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ToastPlugin from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
// import Toasted from 'vue-toasted';
// import Vuex from 'vuex'

import store from './store'
createApp(App)

    .use(router)
    .use(store)
    // .use(Toasted)
    .use(ToastPlugin)
    .mount('#app')
