
import { fetchWrapper } from '../helper/fetch-wrapper'
import store from './index'

export function useAuthStore() {
  async function login(email, password) {
    const user = await fetchWrapper.post('/api/auth/login', { email, password })
    store.dispatch('login', user)
    store.commit('setUser', user)
  }

  async function logout() {
    store.commit('clearUser')
  }

  return {
    login,
    logout
  }
}