import { useAuthStore } from '../store/auth'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API_ENDPOINT || 'http://localhost:3000'

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  post_data: request('POST', true),
  put_data: request('PUT', true)
}

function request (method, form = false) {
  return (url, body) => {
    const baseurl = API_URL + url

    const requestOptions = {
      method: method,
      headers: authHeader(baseurl),
      url: baseurl,
      withCredentials: true,
      credentials: 'include',
      crossDomain: true,
      xsrfHeaderName: 'X-CSRFToken'
    }
    if (body) {
      if (form) {
        requestOptions.data = body
      } else {
        requestOptions.headers['Content-Type'] = 'application/json'
        requestOptions.data = JSON.stringify(body)
      }
    }

    axios.defaults.withCredentials = true
    const { logout } = useAuthStore()

    return axios(requestOptions)
      .then(handleResponse)
      .catch((err) => {
        if (err.response && [401, 403].includes(err.response.status)) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          logout()
        }
        throw err
      })
  }
}

// Helper functions

function authHeader (url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore()
  const isLoggedIn = !!user?.token.token
  const isApiUrl = url.startsWith(API_URL)
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.token.token}` }
  } else {
    return {}
  }
}

function handleResponse (response) {
  const data = response.data && response.data
  if (response.status !== 200) {
    const { user, logout } = useAuthStore()

    if ([401, 403].includes(response.status) && user) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      logout()
    }

    const error = (data && data.message) || response.statusText
    return Promise.reject(error)
  }

  return data
}
