import { createRouter, createWebHistory } from "vue-router";

import DashboardKol from "../components/DashboardKol.vue";
import HomePage from "../views/HomePage.vue";
import LoginPage from "../views/LoginPage";
import KolMember from "../components/KolMember/ListView.vue";
import KOLMemberCreate from "../components/KolMember/KOLMemberCreate.vue";
import KOLMemberUpdate from "../components/KolMember/KOLMemberUpdate.vue";
import KOLMemberProfile from "../components/KolMember/KOlMemberProfile.vue";
import PaymentTransaction from "../components/KolMember/PaymentTransaction.vue"
import store from "../store";

const routes = [
  { path: "/", redirect: { name: "DashboardHome" } },
  { path: "/login", component: LoginPage },
  {
    path: '/home', component: HomePage, children: [
      { path: '', redirect: { name: 'DashboardHome' } },
      { path: 'dashboard', name: 'DashboardHome', component: DashboardKol },
      { path: 'kol-member', name: 'KolMember', component: KolMember },
      {
        path: "kol-member/create",
        name: "KOLMemberCreate",
        component: KOLMemberCreate,
      },
       {
        path: "kol-member/profile/:member_id",
        name: "KOLMemberProfile",
        component: KOLMemberProfile,
      },
      {
        path: "kol-member/update/:id",
        name: "KOLMemberUpdate",
        component: KOLMemberUpdate,
      },
      { path: 'kol-member/payment-transaction/:kol_member_id', name: 'PaymentTransaction', component: PaymentTransaction, }
    ]
  }
]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const isLoggedIn = store.getters['isLogIn']

  if (authRequired && !isLoggedIn) {
    next('/login')
  } else if (to.path == '/login' && isLoggedIn == true) {
    next('/')
  } else {
    next()
  }
})

export default router;
