<template>
  <!-- breadcrumb -->
  <div>
    <nav class="text-sm font-semibold mb-6" aria-label="Breadcrumb">
      <ol class="list-none p-0 inline-flex">
        <li class="flex items-center text-blue-500">
          <a href="#" class="text-gray-700">Home</a>
          <svg
            class="fill-current w-3 h-3 mx-3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
            />
          </svg>
        </li>
        <li class="flex items-center">
          <a href="#" class="text-gray-600">Dashboard</a>
        </li>
      </ol>
    </nav>
    <!-- breadcrumb end -->

    <div class="lg:flex justify-between items-center mb-6">
      <p class="text-2xl font-semibold mb-2 lg:mb-0">Dashboard KOL Tracker</p>
      <!-- <button
        class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow"
      >
        View Logs
      </button> -->
    </div>

    <div class="flex flex-wrap -mx-3 mb-10">
      <MemberTwitter />
      <TweetAll />
      <TweetToday />
      <ViewAll />
    </div>

    <div class="flex flex-wrap -mx-3 mb-10">
      <div class="w-full xl:w-2/3 px-3">
        <p class="text-xl font-semibold mb-4">KOL Cost Per Month</p>

        <div
          class="w-full h-[500px] bg-white border rounded-lg p-4 mb-8 xl:mb-0"
        >
          <KOLCostPerMonth />
        </div>
      </div>

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Recent Tweet</p>

        <div
          class="w-full h-[500px] bg-white border rounded-lg p-4 mb-8 xl:mb-0"
        >
          <RecentTweet v-if="true" />
        </div>
      </div>
    </div>

    <div class="flex flex-wrap -mx-3 mb-10">
      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">KOL Most Payment</p>

        <div
          class="w-full h-[500px] bg-white border rounded-lg p-4 mb-8 xl:mb-0"
        >
          <KOLMostPaymentMember v-if="true" />
        </div>
      </div>

      <div class="w-full xl:w-2/3 px-3">
        <p class="text-xl font-semibold mb-4">KOL Perfomance User View</p>

        <div
          class="w-full h-[500px] bg-white border rounded-lg p-4 mb-8 xl:mb-0"
        >
          <KOLPerformance v-if="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberTwitter from "./Dashboard/MemberTwitter.vue";
import TweetAll from "./Dashboard/TweetAll.vue";
import TweetToday from "./Dashboard/TweetToday.vue";
import ViewAll from "./Dashboard/ViewAll.vue";
import RecentTweet from "./Dashboard/RecentTweet.vue";
import KOLPerformance from "./Dashboard/KOLPerformance.vue";
import KOLCostPerMonth from "./Dashboard/CostKolMember.vue";
import KOLMostPaymentMember from "./Dashboard/MostPaymentMember.vue";
export default {
  components: {
    MemberTwitter,
    TweetAll,
    TweetToday,
    ViewAll,
    RecentTweet,
    KOLPerformance,
    KOLCostPerMonth,
    KOLMostPaymentMember,
  },
  name: "DashboardKol",
  data() {
    return {};
  },
  mounted() {},
};
</script>
