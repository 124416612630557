<!-- LoadingSpinner.vue -->
<template>
    <div class="loading-spinner-overlay">
      <div class="loading-spinner">
        <!-- Insert your loading spinner HTML here -->
        <div class="spinner"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner'
  }
  </script>
  
  <style scoped>
  .loading-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(151, 151, 151, 0.398); /* semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>