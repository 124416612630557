<template>
    <div class="w-full xl:w-1/4 px-3">
        <div class="w-full bg-white border text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
            
            <div v-html="icon_svg"></div>

            <div class="text-gray-700">
                <p class="font-semibold text-3xl">{{ count_number }}</p>
                <p>{{ title }}</p>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        count_number: Number,
        icon_svg: {
            default: `
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 25" stroke-width="1.5"
            stroke="currentColor" class="w-16 h-16">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
          </svg>
            `
        },
    },
    data() {
        return {

        }
    }
}
</script>