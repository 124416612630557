<template>
    <div class="bg-gray-100 min-h-screen">
        <header class="bg-white shadow-md relative">
            <!-- Cover Image -->
            <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c547ba3d-624b-4a5d-892e-8458fd9952fa/d5zfrww-29cca157-7322-4da3-a20e-ee62518f8f7c.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2M1NDdiYTNkLTYyNGItNGE1ZC04OTJlLTg0NThmZDk5NTJmYVwvZDV6ZnJ3dy0yOWNjYTE1Ny03MzIyLTRkYTMtYTIwZS1lZTYyNTE4ZjhmN2MuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.HXmOjvUsAqRSW6tI9C0n4ywXvKjP_e9jC-Psww8cGNQ"
                alt="Cover Image" class="w-full h-[20vh] object-cover">

            <!-- Edit Profile Button (Right Section) -->
            <RouterLink :to="`/home/kol-member/update/${$route.params.member_id}`">
                <button class="absolute top-4 right-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full">
                    Edit Profile
                </button>
            </RouterLink>


            <!-- Profile Image (Hidden on Mobile) -->
            <div class="hidden md:block absolute -bottom-20 left-12">
                <img :src="member.profile_image_url" :alt="member.username"
                    class="h-44 w-44 rounded-full border-4 border-white">
            </div>
        </header>

        <!-- User Profile -->
        <div class="container mx-auto px-4 py-8 ">
            <div class="flex flex-col md:flex-row items-center">
                <!-- Profile Image (Visible on Mobile) -->
                <div class="md:hidden mb-4">
                    <img :src="member.profile_image_url" :alt="member.username"
                        class="h-24 w-24 rounded-full border-4 border-white">
                </div>
                <!-- User Info -->
                <div class="text-center md:text-left md:ml-4 md:mt-16">
                    <h2 class="font-bold text-2xl mb-2">{{ member.username }}</h2>
                    <p class="text-gray-600 my-1"><a target="_blank" :href="`https://twitter.com/${member.username}`">
                            <span
                                class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                @{{ member.username }}
                            </span>
                        </a></p>
                    <p class="text-gray-600 my-1">{{ member.description }}</p>
                    <p class="text-gray-600 my-1"> <span class="font-bold">{{
                Number(member.followers_count || "").toLocaleString() }}</span> Followers
                    </p>
                </div>
            </div>
        </div>

        <!-- Tab -->
        <div class="flex border-b-2 border-gray-200">
            <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index"
                :class="{ 'tab-active': activeTab === index, 'text-black': activeTab === index }"
                class="tab px-4 py-2 mr-2 focus:outline-none">
                {{ tab }}
            </button>
        </div>
        <!-- Tab -->
        <div>
            <TweetKOL v-if="activeTab == 0 && member.username != ''" :username="member.username" />
        </div>
        <div>
            <PaymentTransaction v-if="activeTab == 1" />
        </div>
    </div>


</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper"
import TweetKOL from "./TweetKOL.vue"
import PaymentTransaction from "./PaymentTransaction.vue"
export default {
    components: { TweetKOL, PaymentTransaction },
    data() {
        return {
            activeTab: 0,
            tabs: ['Tweet', 'Payment'], // Add your tab names here
            member: {
                profile_image_url: "",
                description: "",
                twitter_user_id: "",
                username: "",
                followers_count: "",
                cost_usdt: ""
            },
            user: {
                name: 'John Doe',
                username: 'johndoe',
                bio: 'Web Developer. Passionate about coding and technology.',
                location: 'San Francisco, CA',
                profileImage: 'https://pbs.twimg.com/profile_images/1755315677416546304/zQNLgvZC_normal.jpg',
            },
            tweets: [

                // Add more tweets as needed
            ],
        }
    },
    created() {

    },
    mounted() {


        this.getKOlMemberById(this.$route.params.member_id)
    },
    methods: {
        async getKOlMemberById(kol_member_id) {
            try {
                this.$store.dispatch('setLoading', true)
                const member = await fetchWrapper.get(`/api/member/${kol_member_id}`)
                this.member = member.data
                this.$store.dispatch('setLoading', false)
            } catch (error) {
                console.error(error);
            }

        }
    }
}
</script>
<style scoped>
.tab {
    color: #00000091;
}

.tab-active {
    color: #000000;
    border-bottom: 5px solid rgb(0, 132, 255);
}

/* Reset CSS */
button {
    /* transition: background-color 0.3s ease; */
}

button:hover {

    /* background-color: #4f46e5; */
}
</style>