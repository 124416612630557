<template>
    <div class="w-full px-3">
        <div class="w-full h-[500px] m-auto overflow-auto bg-white border rounded-lg p-4">
            <!-- <div>Payment {{ "id:" + $route.params.member_id }}</div> -->
            <div class="lg:flex justify-between items-center mb-6 py-2">
                <!-- <p class="text-2xl font-semibold mb-2 lg:mb-0">PaymentTransaction</p> -->

                <div class="w-[350px]">
                    <Datepicker v-model="dateRange" range @update:model-value="handleDateChange" />
                </div>

                <button @click="modelCreateIsOpen = true"
                    class="bg-blue-500 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Add
                    Payment</button>


            </div>
            <div class="flex flex-wrap -mx-3 mb-20 overflow-x-auto">
                <DynamicTable :headers="headersTable" :rows="resData.data" :action="true" :pathApiDelete="''"
                    @deleted="deleted" @handle-sort="handleSortHeader">
                    <template #_id="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #cost_usdt="{ record }">
                        {{ new Intl.NumberFormat('en-US', {
                        style: 'currency', currency: 'USD'
                    }).format(record.cost_usdt) }}
                    </template>
                    <template #action_custom="{ record }">
                        <div class="w-[300px]">
                            <!-- <button
                        class="py-2 leading-none px-3 font-medium text-yellow-600 bg-gray-100 hover:text-yellow-500 duration-150 hover:bg-gray-50 rounded-lg"
                        @click="openProfile(record._id)">
                        View
                    </button> -->
                            <button @click="modelUpdateIsOpen = true, member_id = record._id"
                                class="m-2 py-2 px-3 font-medium text-indigo-600 bg-gray-100 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg">
                                Edit
                            </button>
                            <button
                                class="m-2 py-2 leading-none px-3 font-medium text-red-600 bg-gray-100 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg"
                                @click="deleteItem(record._id)">
                                Delete
                            </button>

                        </div>
                    </template>
                </DynamicTable>
                <div class="w-full">
                    <div class="flex justify-center items-center">
                        <PaginationApp :prevPage="resData.info.prevPage" :nextPage="resData.info.nextPage"
                            :hasPrevPage="resData.info.hasPrevPage" :hasNextPage="resData.info.hasNextPage"
                            :pages="resData.info.pages" :totalPages="resData.info.totalPages"
                            @page-changed="handlePageChange" />
                    </div>

                </div>
                <ModalBox v-model="modelCreateIsOpen" :title="'Create Payment'">
                    <PaymentTransactionCreate @created="handleCreate" />
                </ModalBox>
                <ModalBox v-model="modelUpdateIsOpen" :title="'Update Payment'">
                    <PaymentTransactionUpdate :member_id="member_id" @updated="handleUpdate" />
                </ModalBox>
            </div>
        </div>
    </div>

</template>
<script>
import {
    ref
    //  ,defineAsyncComponent
} from 'vue'
import { fetchWrapper } from "../../helper/fetch-wrapper"
import DynamicTable from "../../components/DynamicTable.vue";
import ModalBox from "../../components/ModalBox.vue"
import PaginationApp from "../../components/PaginationApp.vue";
import PaymentTransactionCreate from "./PaymentTransactionCreate.vue";
import PaymentTransactionUpdate from "./PaymentTransactionUpdate.vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
export default {
    components: {
        DynamicTable,
        PaginationApp,
        ModalBox,
        PaymentTransactionCreate,
        PaymentTransactionUpdate,
        Datepicker
        // PaginationTableApp,
    },
    setup() {
        var sort = ref({ cost_usdt: -1 })
        var resData = ref({
            status: false,
            data: [],
            info: {
                limit: 0,
                pages: 0,
                totalCount: 0,
                currentCount: 0,
                nextPage: null,
                prevPage: null,
                totalPages: 0,
                hasPrevPage: false,
                hasNextPage: false,
                pagingCounter: 0,
                meta: {},
            },
        })

        const columns = [
            {
                title: '#',
                dataIndex: '_id',
            },
            {
                title: 'Wallet Address',
                dataIndex: 'wallet_address'
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                sorter: {
                    order: 0
                }
            },
            {
                title: 'Token',
                dataIndex: 'token',
            },
            {
                title: 'Cost USDT',
                dataIndex: 'cost_usdt',
                sorter: {
                    order: 0
                }
            },
            {
                title: 'Date Time',
                dataIndex: 'current_time',
                sorter: {
                    order: 0
                }
            },

            {
                title: 'Description',
                dataIndex: 'description'
            },


        ];

        var headersTable = ref(columns)

        var modelCreateIsOpen = ref(false)
        var modelUpdateIsOpen = ref(false)
        var member_id = ref('')

        const startOfYear = moment().startOf('year');
        const endOfYear = moment().endOf('year');

        const dateRange = ref([
            startOfYear,
            endOfYear
        ]);



        return {
            resData,
            headersTable,
            sort,
            modelCreateIsOpen,
            modelUpdateIsOpen,
            member_id,
            dateRange
        };
    },
    methods: {
        openProfile(member_id) {

            this.$router.push(`/home/kol-member/profile/${member_id}`)
        },
        handleSortHeader: function (new_header, sort) {
            this.headersTable = new_header
            this.sort = sort
            this.fetchNew()

        },
        fetchNew: async function () {
            if (this.dateRange == null) {
                this.dateRange = [
                    moment().startOf('year'),
                    moment().endOf('year')
                ]
            }
            await this.fetchData({
                limit: this.resData.info.limit,
                skip: this.resData.info.pages,
                sort: this.sort,
                search: {
                    createdAt: {
                        start: new Date(moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss")),
                        end: new Date(moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"))
                    },
                },
                active: true,
            })
        },
        handleDelete: async function () {
            await this.fetchNew()
        },
        handlePageChange: async function (info) {
            await this.fetchData({
                limit: info.limit,
                skip: info.skip,
                sort: this.sort,
                search: "",
                active: true,
            })
        },
        fetchData: async function (pagination) {
            try {
                this.$store.dispatch('setLoading', true)
                const member = await fetchWrapper.post(`/api/payment-transaction/all/${this.$route.params.member_id}`, pagination);
                if (member && member.status) {
                    this.resData = member;
                    this.$store.dispatch('setLoading', false)
                }
            } catch (error) {
                console.error(error);
            }

        },
        async deleteItem(id) {
            if (confirm(`do you want to delete id: ${id}`)) {
                await fetchWrapper.delete(`/api/payment-transaction/${id}`).then(async () => {
                    await this.handleDelete()
                }).catch((error) => {
                    alert(`Error: ${error.message}`);
                    this.back()
                })
            }

        },
        async handleCreate() {
            await this.fetchNew()
            this.modelCreateIsOpen = false
        },
        async handleUpdate() {
            await this.fetchNew()
            this.modelUpdateIsOpen = false
        },
        async handleDateChange() {
            await this.fetchNew()
        }
    },
    async created() {
        await this.fetchData({
            limit: 10,
            skip: 1,
            sort: this.sort,
            search: {
                createdAt: {
                    start: new Date(moment(this.dateRange[0]).format("YYYY-MM-DD HH:mm:ss")),
                    end: new Date(moment(this.dateRange[1]).format("YYYY-MM-DD HH:mm:ss"))
                },
            },
            active: true,
        });
    }
}
</script>
