<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">

      <SidebarApp />

      <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

        <NavbarApp />

        <div class="p-6 bg-gray-100 mb-20">
          <router-view />
        </div>

        <footer-app />

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import SidebarApp from '../components/SidebarApp.vue'
import NavbarApp from '../components/NavbarApp.vue'
import FooterApp from '../components/FooterApp.vue'

export default {
  name: 'HomePage',
  computed: {
    ...mapState(['sideBarOpen'])
  },
  components: {
    SidebarApp,
    NavbarApp,
    FooterApp
  }
}
</script>
