<template>
  <div class="container mx-auto">
    <form @submit.prevent="submitForm" class="max-w-md mx-auto mt-8 bg-white p-8 rounded-md shadow-md">
      <div class="mb-4">
        <label for="twitter_user_id" class="block text-gray-700 font-bold mb-2">Twitter User ID:</label>
        <input v-model="form.twitter_user_id" type="text" id="twitter_user_id" name="twitter_user_id"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="followers_count" class="block text-gray-700 font-bold mb-2">Followers Count:</label>
        <input v-model.number="form.followers_count" type="number" id="followers_count" name="followers_count"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="username" class="block text-gray-700 font-bold mb-2">Username:</label>
        <input v-model="form.username" type="text" id="username" name="username"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="cost_usdt" class="block text-gray-700 font-bold mb-2">Cost (USDT):</label>
        <input v-model.number="form.cost_usdt" type="number" id="cost_usdt" name="cost_usdt"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="is_active" class="block text-gray-700 font-bold mb-2">Is Active:</label>
        <input v-model="form.is_active" type="checkbox" id="is_active" name="is_active" class="mr-2 leading-tight">
        <span class="text-sm">Active</span>
      </div>
      <div class="flex justify-end">
        <button type="submit"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Create</button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper";

export default {
  data() {
    return {
      form: {
        twitter_user_id: '',
        followers_count: 0,
        username: '',
        cost_usdt: 0,
        is_active: true
      }
    };
  },
  methods: {
    async submitForm() {
      // Handle form submission
      // You can perform API calls here to create the data
      await fetchWrapper.post("/api/member/create", this.form).then(() => {
        this.$emit("created");
        this.$toast.open({ type: "success", message: "Create KOL member successfully" })

      }).catch((error) => {
        console.log(error);
        this.$toast.open({ type: "error", message: error.message })
      })

    }
  }
};
</script>