<template>
  <div>
    <!-- Modal Background -->
    <div v-if="value" class="fixed inset-0 bg-black opacity-50 z-50"></div>

    <!-- Modal Dialog -->
    <div v-if="value" class="fixed inset-0 p-4 z-50">
      <!-- Modal content -->
      <div
        class="fixed top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 bg-white rounded-lg shadow md:w-2/3 xl:w-2/3 sm:w-full overflow-x-auto">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-400">
          <h3 class="text-xl font-semibold text-gray-900">
            {{ title }}
          </h3>
          <button type="button"
            class="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="default-modal" @click="closeModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5 space-y-4 max-h-[70vh] overflow-y-auto">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['modelValue', "title"],
  emits: ['update:modelValue'],
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false) // Emit event to update the prop
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
};
</script>