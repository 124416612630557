<template>

 <CardBox title="View Tweet" :count_number="viewCount" icon_svg=' <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 25" stroke-width="1.5"
            stroke="currentColor" class="w-16 h-16">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>' />

  
</template> 

<script>
import CardBox from "../CardBox.vue"
import { fetchWrapper } from '../../helper/fetch-wrapper'

export default {
  components: {
    CardBox
  },
  data () {
    return {
      viewCount: 0,
    }
  },
  mounted() {
    this.getViewAll()
  },
  methods: {
   async getViewAll() {
      const view = await fetchWrapper.get('/api/dashboard/view-all');
      this.viewCount = view.data.toLocaleString();
    }
  }
}
</script>
