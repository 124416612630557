<template>
  <div class="container mx-auto">
    <form @submit.prevent="submitForm" class="max-w-md mx-auto mt-8 bg-white p-8 rounded-md shadow-md">
      <!-- <div class="mb-4">
        <label for="kol_member_id" class="block text-gray-700 font-bold mb-2">Member ID:</label>
        <input v-model="form.kol_member_id" type="text" id="kol_member_id" name="kol_member_id" disabled
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div> -->

      <div class="mb-4">
        <label for="wallet_address" class="block text-gray-700 font-bold mb-2">Wallet Address</label>
        <input v-model="form.wallet_address" type="text" id="wallet_address" name="wallet_address"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="token" class="block text-gray-700 font-bold mb-2">Token</label>
        <input v-model="form.token" type="text" id="token" name="token"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="amount" class="block text-gray-700 font-bold mb-2">Amount:</label>
        <input v-model.number="form.amount" type="number" id="amount" name="amount"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="cost_usdt" class="block text-gray-700 font-bold mb-2">Cost (USDT):</label>
        <input v-model.number="form.cost_usdt" type="number" id="cost_usdt" name="cost_usdt"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500">
      </div>
      <div class="mb-4">
        <label for="description" class="block text-gray-700 font-bold mb-2">Description:</label>
        <textarea v-model="form.description" type="text" id="kol_member_id" name="kol_member_id"
          class="w-full border rounded-md px-3 py-2 text-gray-700 focus:outline-none focus:border-blue-500" />
      </div>
      <div class="mb-4">
        <label for="is_active" class="block text-gray-700 font-bold mb-2">Is Active:</label>
        <input v-model="form.is_active" type="checkbox" id="is_active" name="is_active" class="mr-2 leading-tight">
        <span class="text-sm">Active</span>
      </div>
      <div class="flex justify-center">
        <button type="submit"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchWrapper } from "../../helper/fetch-wrapper";
export default {
  props: {
    member_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        // kol_member_id: this.$route.params.member_id,
        description: '',
        token: '',
        wallet_address: '',
        amount: 0,
        cost_usdt: 0,
        is_active: true,
        current_time: new Date()
      },
      id: null,
    };
  },
  methods: {
    async submitForm() {
      // Handle form submission
    
      // You can perform API calls here to create the data
      // this.$store.dispatch('setLoading', true)
      await fetchWrapper.put(`/api/payment-transaction/update/${this.member_id}`, this.form).then((data) => {
        // this.$store.dispatch('setLoading', false)
        this.$emit("updated");
        return data
      }).catch((error) => {
        // this.$store.dispatch('setLoading', false)
    
        alert(`${error}`)
        this.$emit("updated");
      })
      // this.back()
    },
    back() {
      // Handle form submission
   
      this.$router.push({ name: 'KolMember' });
      // You can perform API calls here to create the data
    },
    async getById(id) {
      const member = fetchWrapper.get("/api/payment-transaction/" + id).then((data) => {
        // console.log(data)
        return data.data
      }).catch((error) => {
        alert(`Error: ${error}`);
        // this.$emit("updated");
        this.back()
      })
      return member
    }
  },
  async created() {
    const member = await this.getById(this.member_id)
    this.form = member
  }
};
</script>