<template>
  <div>
    <div class="w-[350px]">
      <Datepicker v-model="dateRange" range @update:model-value="handleDateChange" />
    </div>
    <div ref="chart"></div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchWrapper } from "../../helper/fetch-wrapper";
import Highcharts from "highcharts";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "BarChart",
  components: { Datepicker },
  data() {
    return {
      loaded: false,
    };
  },
  setup() {
    const chart = ref(null);

    onMounted(() => {
      // renderChart()
      fetchData();
    });

    const chart_option = ref({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: "KOL Most Payment",
      },
      plotOptions: {
        pie: {
            innerSize: '30%',
        },
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: [{
            enabled: true,
            distance: 20
          }, {
            enabled: true,
            distance: -40,
            format: '{point.percentage:.1f}%',
            style: {
              fontSize: '1.2em',
              textOutline: 'none',
              opacity: 0.7
            },
            filter: {
              operator: '>',
              property: 'percentage',
              value: 10
            }
          }]
        }
      },
      tooltip: {
        formatter: function () {
          return `<b>${this.point.name}</b>: ${this.point.y}% (${this.point.total_cost_usdt} USD)`;
        }
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: [
            {
              name: "Water",
              y: 55.02,
            },
            {
              name: "Fat",
              sliced: true,
              selected: true,
              y: 26.71,
            },
            {
              name: "Carbohydrates",
              y: 1.09,
            },
            {
              name: "Protein",
              y: 15.5,
            },
            {
              name: "Ash",
              y: 1.68,
            },
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              // `this` refers to the point object
              return `<b>${this.point.name}</b>: ${this.point.y} (${this.point.total_cost_usdt} USDT)`;
            }
          }

        },
      ],
    });

    const renderChart = (chart_option) => {
      if (chart.value) {
        Highcharts.chart(chart.value, chart_option);
      }
    };

    const startOfYear = moment().startOf("year");
    const endOfYear = moment().endOf("year");

    const dateRange = ref([startOfYear, endOfYear]);

    const handleDateChange = () => {
      fetchData();
    };

    const fetchData = async () => {

      if (dateRange.value != null) {
        fetchWrapper
          .post("/api/dashboard/kol-most-payment", {
            date_start: moment(dateRange.value[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            date_end: moment(dateRange.value[1]).format("YYYY-MM-DD HH:mm:ss"),
            limit: 1000,
          })
          .then((response) => {
            chart_option.value.series[0].data = response.data.map(data => {
              return {
                name: data.username,
                y: Number(Number(data.ratio).toFixed(2)),
                total_cost_usdt: Number(data.total_cost_usdt).toLocaleString()
              }
            })
            renderChart(chart_option.value);
          });
      }

    };

    return { chart, dateRange, handleDateChange };
  },
};
</script>
