<template>
  <div>
    <div class="w-[350px]">
      <Datepicker v-model="dateRange" range @update:model-value="handleDateChange" />
    </div>
    <div ref="chart"></div>
    <ModalBox v-model="modalDillDownIsOpen" :title="'View Detail'">
      <DynamicTable :headers="headersTable" :rows="dilldownRecord" :action="false">
        <template #_id="{index}">{{ index+1 }}</template>
        <template #username="{ record }">
          <a target="_blank" :href="`https://twitter.com/${record.kolmembers.username}`">
            <span
              class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
              {{ record.kolmembers.username }}
            </span>
          </a>
        </template>
      </DynamicTable>
    </ModalBox>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { fetchWrapper } from "../../helper/fetch-wrapper";
import DynamicTable from "../../components/DynamicTable.vue";
import Highcharts from "highcharts";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import ModalBox from "../../components/ModalBox.vue"
export default {
  name: "BarChart",
  components: {
    Datepicker,
    ModalBox,
    DynamicTable
  },
  data() {
    return {
      loaded: false,
    };
  },
  setup() {
    const chart = ref(null);
    const modalDillDownIsOpen = ref(false)

    var dilldownRecord = ref([])
    const handleClickChart = async (record) => {
      dilldownRecord.value = []
      var month_id = record.point.category
      var find_record = data_response.value.find(x => x._id == month_id)

      var data_body = {
        limit: 1000,
        skip: 1,
        sort: { current_time: -1 },
        search: {
          current_time: {
            start: find_record.start_of_date,
            end: find_record.end_of_date
          },
        },
        active: true,
      }

      const transaction = await fetchWrapper.post(`/api/payment-transaction/all`, data_body);
      if (transaction && transaction.status) {
        dilldownRecord.value = transaction.data
        modalDillDownIsOpen.value = true
      }
    }

    const columns = [
      {
        title: '#',
        dataIndex: '_id',
      },
      {
        title: 'Username',
        dataIndex: 'username',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',

      },
      {
        title: 'Token',
        dataIndex: 'token',
      },
      {
        title: 'Cost USDT',
        dataIndex: 'cost_usdt',

      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Date Time',
        dataIndex: 'current_time',
      }
    ];

    var headersTable = ref(columns)
    const data_response = ref([])

    onMounted(() => {
      fetchData();
    });

    const chart_option = ref({
      chart: {
        type: "column",

      },
      title: {
        text: "Payment Monthly",
      },
      xAxis: {
        categories: ["1", "2", "3", "4", "5"],
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      plotOptions: {
        column: {
          events: {
            click: handleClickChart
          }
        }
      },
      series: [
        {
          name: "USDT",
          data: [1, 3, 6, 8, 50],

        },
      ],
    });

    const renderChart = (chart_option) => {
      if (chart.value) {
        Highcharts.chart(chart.value, chart_option);
      }
    };

    const startOfYear = moment().startOf("year");
    const endOfYear = moment().endOf("year");

    const dateRange = ref([startOfYear, endOfYear]);

    const handleDateChange = () => {
      fetchData();
    };


    const fetchData = async () => {
      if (dateRange.value != null) {
        const response = await fetchWrapper.post(
          "/api/dashboard/kol-total-cost",
          {
            date_start: moment(dateRange.value[0]).format("YYYY-MM-DD HH:mm:ss"),
            date_end: moment(dateRange.value[1]).format("YYYY-MM-DD HH:mm:ss"),
          }
        );

        data_response.value = response.data

        chart_option.value.xAxis.categories = response.data.map(
          (item) => item._id
        );
        chart_option.value.series[0] = {
          name: "Cost USDT",
          data: response.data.map((item) => item.total_cost_usdt),
        };

        renderChart(chart_option.value);
      }

    };

    return { headersTable, chart, dateRange, handleDateChange, modalDillDownIsOpen, dilldownRecord };
  },
};
</script>
